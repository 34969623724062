import { useState, useEffect } from "react";
// import { LineChart } from '@mui/x-charts/LineChart';
// import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';
import QueryTable from "../../DatabaseTable";
import { toast } from "react-toastify";
import { formatDate } from "..";
import { useMeasure } from "react-use";

const  toNumber = value => {
    if (!value) return 0;
    const v = parseFloat(value.toString().replace(/,/g, ''));
    return isNaN(v) ? 0 : v;
}


const CustomizedAxisTick = (...args)=>{
    const {  x, y, stroke, payload } = args[0];
    return (
        <g transform={`translate(${x},${y})`}>
          <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">
            {payload.value}
          </text>
        </g>
      );
  };


const TargetAsinMultiDate = ({profile, start, end}) => {
    const [ref, bounds] = useMeasure();

    const [showDetails, setShowDetails] = useState(true);
    const [lineData, setLineData] = useState([]);

    useEffect(() => {
        setTimeout(() => {
            setShowDetails(false);
        }, 10000);
    }, []);

    const getCPCData = target_id => {
        const promise = async () => {
            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    query_id: 'target-cpc',
                    profile, start, end, 
                    args: {target_id: `${target_id}`}})
            }
            const response = await fetch('/query-table', options);
            const data = await response.json();
            console.log('cpc data', !!data?.table?.length, data?.table?.length, data);
            if(data?.table?.length) {
                console.log('table', data.table.map(row=>({...row, date: new Date(row.date)})))
                setLineData(data.table.map(row=>({...row, date: (new Date(row.date).toLocaleDateString('en-US', { 
                    month: 'short', 
                    day: 'numeric', 
                    year: 'numeric' 
                  }))})));//.map(row=>({...row, date: new Date(row.date)})));
            }
        }

        toast.promise(
            promise(), {
                pending: 'Fetching',
                success: 'Fetched',
                error: 'Failed to fetch'
            }
        )
    }

    return <div>
        <h2> Target ASINs Multi-Date Range </h2>
        {!showDetails ? null : <>
            <p>note: This does not use the date range on the top right. </p>
            <p>Even though all tables need a daterange to communicate with the server.</p>
        </>}
        <div style={{maxWidth: '75vw'}}>
        <QueryTable 
            queryId='asin-target-multi-date-range'
            profile={profile}
            start={start}
            end={end}
            extra={[{
                field: 'details', headerName: 'Details', width: 150, 
                renderCell: params=>(<button onClick={()=>getCPCData(params?.row?.['Target ID'])}> Details </button>)
            }]}
            showFetchForLater
            showCancel
        />
        </div>
        <br/>
        {false //!lineData.length 
        ? null : 
        <div style={{width: '75wv', height: 400, overflowX: 'scroll'}}>
            {/* <LineChart
                dataset={lineData.slice(0, 10).map(row=>({...row, cpc: Math.random() * 5.0}))}
                xAxis={[
                    {
                        id: 'Day',
                        dataKey: 'date',
                        scaleType: 'time',
                        valueFormatter: (date) => formatDate(date),
                    },
                ]}
                series={[{
                    // label: 'CPC',
                    id: 'cpc',
                    dataKey: 'cpc',
                    // stack: 'total',
                    area: true,
                    // showMark: false,
                    color: 'lightblue',
                }]}
                // xAxis={[{ data: lineData.map(row=>row.cpc), valueFormatter: (value) => !value ? value : `$ ${toNumber(value).toFixed(2)}`, }]}
                height={200}
                // width={lineData.length * 150}
                margin={{ top: 10, bottom: 20 }}
            /> */}
            <LineChart width={window.screen.width * .75} height={300} data={lineData.length ? lineData : [{cpc: 0.50, date: '2024-05-01'}]} margin={{ top: 5, right: 20, bottom: 50, left: 0 }}>
                <Line type="monotone" dataKey="cpc" stroke="#8884d8" />
                <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                <XAxis dataKey="date" tick={<CustomizedAxisTick />} />
                <YAxis />
                <Tooltip />
            </LineChart>
        </div>}
    </div>
}


export default TargetAsinMultiDate;