import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useSessionState } from '../../../../util/util';
import { Box, Modal } from '@mui/material';

{/* <div style="position: relative; padding-bottom: 62.5%; height: 0;"><iframe src="https://www.loom.com/embed/dfd54c00887141fda426faa73dec1107?sid=2f5d25ed-5c82-4387-aeb4-8fe837d9fcbf" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"></iframe></div> */}
const loomLink = 'https://www.loom.com/share/dfd54c00887141fda426faa73dec1107?sid=07a0b4e6-4bca-49e6-bd0a-b1982d5e3807';  // 'https://www.loom.com/share/eb858bf3a53348329d443fe6dd4db52f?sid=3ebc769e-1af7-4453-b4a5-00761c953f3b'
const loomEmbededLink = 'https://www.loom.com/embed/dfd54c00887141fda426faa73dec1107?sid=2f5d25ed-5c82-4387-aeb4-8fe837d9fcbf';


const LoomToolTip = ({title='How to use the tool', loomLink, loomEmbededLink}) => {
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const openLoom = () => {
        window.open(
            loomLink,
            '_blank'
        );
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '75%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    return <>
        {/* <button className='btn' onClick={handleFetchAccounts}>
            Go Through Accounts
        </button> */}
        <button style={{margin: 15}} className='btn' onClick={handleOpen}>
            {title}
        </button>
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
        <Box sx={style}>
            <div style={{position: 'relative', 'paddingBottom': '62.5%', height: 0}}>
                <iframe 
                    src={loomEmbededLink} 
                    frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen 
                    style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}}>
                </iframe>
            </div>
            <button style={{margin: 15}} className='btn' onClick={openLoom}>
                or Open in Loom
            </button>
        </Box>
        </Modal>
    </>
}


const SQPRSrapping = ({accounts}) => {
    const initialAccountsByEmail = !accounts ? {} : accounts.reduce((acc, a) => {
        if (!acc[a.email]) acc[a.email] = [];
        acc[a.email].push(a);
        return acc;
    }, {});

    const [accountsByEmail, setAccountsByEmail] = useSessionState(
        initialAccountsByEmail, 
        'sqpr-srapping-accounts-by-email'
    )
    // const [account, setAccount] = useState(null);
    const [index, setIndex] = useSessionState(
        {email: null, index: -1}, 
        'sqpr-srapping-index'
    );
    
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    useEffect(() => {
        const initialAccountsByEmail = !accounts ? {} : accounts.reduce((acc, a) => {
            if (!acc[a.email]) acc[a.email] = [];
            acc[a.email].push(a);
            return acc;
        }, {});
        setAccountsByEmail(initialAccountsByEmail);
    }, [accounts]);

    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '75%',
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
    };

    const handleFetchAccounts = async () => {
        const v = accounts.reduce((acc, a) => {
            if (!acc[a.email]) acc[a.email] = [];
            acc[a.email].push(a);
            return acc;
        }, {});

        setAccountsByEmail(v);
        
        setIndex({
            email: Object.keys(v).sort((a, b) => a > b)[0], 
            index: 0
        });
    }

    const handleStop = () => {
        setIndex({
            email: null,
            index: -1
        });
    }

    const handleNext = () => {
        const accounts = accountsByEmail[index.email];
        const nextIndex = index.index + 1;
        if (nextIndex >= accounts.length) {
            setIndex({
                email: null,
                index: -1
            });
        } else {
            setIndex({
                ...index,
                index: nextIndex
            });
        }
    }

    const Display = ({account}) => {
        const openSellerCenteral = () => {
            window.open(account.sc_link, '_blank');
        }

        const copySCUrl = () => {
            navigator.clipboard.writeText(account.sc_link);
        }

        const openSQPR = () => {
            window.open(account.sqpr_link, '_blank');
        }

        const copyUrl = () => {
            navigator.clipboard.writeText(account.sqpr_link);
        }

        const getClipboard = async () => {
            const promise = async () => {
                const config = await navigator.clipboard.readText();
                const body = {
                    profile: account.profileId,
                    name: account.name,
                    api_id: account.api.id,
                    config: config,
                }
                const response = await fetch('/update-account-config', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(body)
                });
                if(!response.ok) throw new Error('Failed to paste');
                const data = await response.json();
                const status = data?.status;
                if(status !== 'success') {
                    const msg = data?.error || 'Unknown error';
                    console.error(msg);
                    throw new Error('Failed to paste');
                }
                handleNext();
                toast.success('Next Account');
            }

            toast.promise(promise, {
                pending: 'Pasting Config to Server',
                success: 'Pasted',
                error: 'Failed to paste'
            })
            .then(() => {
                navigator.clipboard.writeText('');
            })
        }

        const openLoom = () => {
            window.open(
                loomLink,
                '_blank'
            );
        }

        if(!account) return <>
            {/* <button className='btn' onClick={handleFetchAccounts}>
                Go Through Accounts
            </button> */}
            <LoomToolTip title='How to use the tool' loomLink={loomLink} loomEmbededLink={loomEmbededLink}/>
            {/* <button style={{margin: 15}} className='btn' onClick={handleOpen}>
                How to use the tool
            </button> */}
            <h2> Get Configurations for accounts in: </h2>
            {Object.keys(accountsByEmail).map(email=>{
                return <><button style={{margin: 15}} className='btn' onClick={()=>{
                    setIndex({
                        email,
                        index: 0
                    });
                }}>{email}</button><br/></>
            })}
            {/* <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
            <Box sx={style}>
                <div style={{position: 'relative', 'paddingBottom': '62.5%', height: 0}}>
                    <iframe 
                        src={loomEmbededLink} 
                        frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen 
                        style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}}>
                    </iframe>
                </div>
                <button style={{margin: 15}} className='btn' onClick={openLoom}>
                    or Open in Loom
                </button>
            </Box>
            </Modal> */}
        </>;


        return <>
            <h1>{account.email}</h1>
            <button className='btn' onClick={handleNext}>Force Next</button>
            <button className='btn' onClick={handleStop}>Stop</button>
            <h2>{account.name}</h2>
            {!account.sc_link ? <h3> Step 1: Add Seller Central Link to Account (No Seller Center Link)</h3> : <>
                <h3>Step 1: Open Seller Center</h3>
                <button className='btn' onClick={openSellerCenteral}>Open Url</button>
                <button className='btn' onClick={copySCUrl}>Copy Url</button>
            </>}
            <br/>
            {!account.sqpr_link ? <h3>Step 2: (No Scrapper Link)</h3> : <>
                <h3>Step 2: Open Scrapper Link & Copy `node.js fetch` from `report` or 2nd `api`</h3>
                <button style={{marginTop: 15}}  className='btn' onClick={openSQPR}>Open Url</button>
                <button className='btn' onClick={copyUrl}>Copy Url</button>
            </>}
            <br/>
            <h3> Step 3: Paste `node.js fetch` to Server </h3>
            <button className='btn' style={{marginTop: 15}} onClick={getClipboard}>Paste</button>
            {/* <pre>
                {JSON.stringify(account, null, 2)}
            </pre> */}
        </>
    }

    return <>
        <h2> SQPR Srapping Tool </h2>
        <Display account={accountsByEmail?.[index.email]?.[index.index]}/>
    </>
}


export default SQPRSrapping;


// const SQPRSrapping = ({accounts}) => {
//     // const [currentEmail, setCurrentEmail]
//     const initialAccountsByEmail = !accounts ? {} : accounts.reduce((acc, a) => {
//         if (!acc[a.email]) acc[a.email] = [];
//         acc[a.email].push(a);
//         return acc;
//     }, {});

//     const [accountsByEmail, setAccountsByEmail] = useSessionState(
//         initialAccountsByEmail, 
//         'sqpr-srapping-accounts-by-email'
//     );
//     // const [account, setAccount] = useState(null);
//     const [index, setIndex] = useSessionState(
//         {email: null, index: -1}, 
//         'sqpr-srapping-index'
//     );
    
//     const handleFetchAccounts = async () => {
//         const v = accounts.reduce((acc, a) => {
//             if (!acc[a.email]) acc[a.email] = [];
//             acc[a.email].push(a);
//             return acc;
//         }, {});

//         setAccountsByEmail(v);
        
//         setIndex({
//             email: Object.keys(v).sort((a, b) => a > b)[0], 
//             index: 0
//         });
//     }

//     const handleStop = () => {
//         setIndex({
//             email: null,
//             index: -1
//         });
//     }

//     const handleNext = () => {
//         const accounts = accountsByEmail[index.email];
//         const nextIndex = index.index + 1;
//         if (nextIndex >= accounts.length) {
//             const i = Object.keys(accountsByEmail).sort((a, b) => a > b).indexOf(index.email) + 1;
//             const emails = Object.keys(accountsByEmail).sort((a, b) => a > b);
//             if (i >= emails.length) {
//                 setIndex({
//                     email: null,
//                     index: -1
//                 });
//             } else {
//                 setIndex({
//                     email: emails[i],
//                     index: 0
//                 });
//                 toast.success(`Showing ${emails[i]}`);
//             }
//         } else {
//             setIndex({
//                 ...index,
//                 index: nextIndex
//             });
//         }
//     }

//     const Display = ({account}) => {
//         /*
//         // example
//          account = {
//             "id": "f7b83070-7d4a-11ef-8e21-81af2a1f2ed1-010e1c8e-00f7-4936-b59b-05346bf99848",
//             "name": "XS Sights US",
//             "profileId": "2601689235985516",
//             "sc_link": null,
//             "br_link": null,
//             "sqpr_link": "https://sellercentral.amazon.com/business-reports/ref=xx_sitemetric_favb_xx#/report?id=102%3ADetailSalesTrafficBySKU&chartCols=&columns=0%2F1%2F2%2F3%2F8%2F9%2F14%2F15%2F20%2F21%2F26%2F27%2F28%2F29%2F30%2F31%2F32%2F33%2F34%2F35%2F36%2F37",
//             "email": "team@junglr.com",
//             "api": {
//                 "profileId": "2601689235985516",
//                 "countryCode": "US",
//                 "currencyCode": "USD",
//                 "dailyBudget": "999999999.0",
//                 "timezone": "America/Los_Angeles",
//                 "accountInfo_marketplaceStringId": "ATVPDKIKX0DER",
//                 "accountInfo_id": "AWFWYPS1FGT6Y",
//                 "accountInfo_type": "seller",
//                 "accountInfo_name": "XS Sights",
//                 "accountInfo_validPaymentMethod": "True",
//                 "id": "XS Sights ~ US ~ seller"
//             }
//             }
//          */

//         const openSellerCenteral = () => {
//             window.open(account.sc_link, '_blank');
//         }

//         const copySCUrl = () => {
//             navigator.clipboard.writeText(account.sc_link);
//         }

//         const openSQPR = () => {
//             window.open(account.sqpr_link, '_blank');
//         }

//         const copyUrl = () => {
//             navigator.clipboard.writeText(account.sqpr_link);
//         }

//         const getClipboard = async () => {
//             const promise = async () => {
//                 const config = await navigator.clipboard.readText();
//                 const body = {
//                     profile: account.profileId,
//                     name: account.name,
//                     api_id: account.api.id,
//                     config: config,
//                 }
//                 const response = await fetch('/update-account-config', {
//                     method: 'POST',
//                     headers: {
//                         'Content-Type': 'application/json'
//                     },
//                     body: JSON.stringify(body)
//                 });
//                 if(!response.ok) throw new Error('Failed to paste');
//                 const data = await response.json();
//                 const status = data?.status;
//                 if(status !== 'success') {
//                     const msg = data?.error || 'Unknown error';
//                     console.error(msg);
//                     throw new Error('Failed to paste');
//                 }
//                 handleNext();
//                 toast.success('Next Account');
//             }

//             toast.promise(promise, {
//                 pending: 'Pasting Config to Server',
//                 success: 'Pasted',
//                 error: 'Failed to paste'
//             })
//             .then(() => {
//                 navigator.clipboard.writeText('');
//             })
//         }

//         if(!account) return <>
//             <button className='btn' onClick={handleFetchAccounts}>
//                 Go Through Accounts
//             </button>
//         </>;


//         return <>
//             <h1>{account.email}</h1>
//             <button className='btn' onClick={handleNext}>Force Next</button>
//             <button className='btn' onClick={handleStop}>Stop</button>
//             <h2>{account.name}</h2>
//             {!account.sc_link ? <h3> Step 1: Add Seller Central Link to Account (No Seller Center Link)</h3> : <>
//                 <h3>Step 1: Open Seller Center</h3>
//                 <button className='btn' onClick={openSellerCenteral}>Open Url</button>
//                 <button className='btn' onClick={copySCUrl}>Copy Url</button>
//             </>}
//             <br/>
//             {!account.sqpr_link ? <h3>Step 2: (No Scrapper Link)</h3> : <>
//                 <h3>Step 2: Open Scrapper Link & Copy `node.js fetch` from `report` or 2nd `api`</h3>
//                 <button style={{marginTop: 15}}  className='btn' onClick={openSQPR}>Open Url</button>
//                 <button className='btn' onClick={copyUrl}>Copy Url</button>
//             </>}
//             <br/>
//             <h3> Step 3: Paste `node.js fetch` to Server </h3>
//             <button className='btn' style={{marginTop: 15}} onClick={getClipboard}>Paste</button>
//             {/* <pre>
//                 {JSON.stringify(account, null, 2)}
//             </pre> */}
//         </>
//     }

//     return <>
//         <Display account={accountsByEmail?.[index.email]?.[index.index]}/>
//     </>
// }


// export default SQPRSrapping;
