import React, { useState } from 'react';
import { TextField, Switch, FormGroup, FormControl, FormControlLabel } from '@mui/material';
import QueryTable from '../../../DatabaseTable';
import {toast} from 'react-toastify';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
// import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';


const ResetButton = ({params}) => {
  const [loading, setLoading] = useState(false);

  const downloadTextFile = (username, txt) => {
      const element = document.createElement("a");
      const file = new Blob([txt], {type: 'text/plain'});
      element.href = URL.createObjectURL(file);
      element.download = `${username}.conf`;
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
      URL.revokeObjectURL(element.href);
  }

  const handleClick = async () => {
    downloadTextFile(params.row.Username, params.row.Config);
    // try {
    //   toast(JSON.stringify(params.row, undefined, 2))
    // } catch (error) {
    //   console.error("Error uploading file:", error);
    //   toast(JSON.stringify(Object.keys(params), undefined, 2))
    // }
  };

  return <button className='btn' onClick={handleClick}>Download</button>;
}

function renderResetButton(params) {
  return <ResetButton params={params}/>;
}

const col = {
  field: 'downloadConfig',
  headerName: 'Download Config',
  // valueFormatter: (value) => value,
  renderCell: renderResetButton,
  width: 150,
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const QRCode = ({username}) => {
  const [open, setOpen] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return <>
    <Button onClick={handleOpen}>Open</Button>
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {loaded ? null : <p> Loading ... </p>}
        <img src={`/vpn-user-qr/${username}`} alt="QR Code" onLoad={()=>setLoaded(true)}/>
      </Box>
    </Modal>
  </>
}

const renderQRCode = (params) => {
  return <QRCode username={params.row.Username}/>;
  return <img src={`/vpn-user-qr/${params.row.Username}`} alt="QR Code" />;
}

const qrCol = {
  field: 'qrCode',
  headerName: 'QR Code',
  renderCell: renderQRCode,
  width: 150,
};

const CuttingBleeders = ({profile, start, end}) => {
  const [username, setUsername] = useState('');

  const createVpnUser = async event => {
    const promise = async () => {
        const json = { username };
        const body = JSON.stringify(json);

        try {
        const response = await fetch('/create-vpn-user', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            credentials: 'include',
            body: body,
        });

        if (response.ok) {
            const data = await response.json();
            if(data.status === 'success') {} 
            else {
            throw new Error(data.error || 'Unknown error');
            }
        } else {
            console.error('File upload failed.');
            console.error(response.statusText);
            throw new Error('File upload failed.');
        }
        } catch (error) {
        console.error("Error uploading file:", error);
        throw error;
        }
    };

    toast.promise(promise(), {
        pending: 'Creating Job for VPN User',
        success: 'Job to Create VPN User Submited',
        error: 'Failed to create VPN User'
    });
  };

  const textFieldStyle = {marginBottom: 15}

  return (
    <div>
      <h2>VPN Users</h2>
      
      <TextField 
        label='UserName' 
        value={username} 
        onChange={e=>setUsername(e.target.value || '')} 
        style={textFieldStyle}
      />
      <br/>
      <button className='btn' onClick={createVpnUser}>Create VPN User</button>
      <br/><br/>
      <QueryTable 
        queryId='get-vpn-user'
        profile={profile}
        start={start}
        end={end}
        skip={['Config']}
        extra={[col, qrCol]}
      />
    </div>
  );
}

export default CuttingBleeders;
