import {useState, useEffect, useRef} from 'react';
// import CircularProgress from '@mui/material/CircularProgress';
// import Box from '@mui/material/Box';
// import TextField from '@mui/material/TextField';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import DataTable from "../Table";
import { toast } from 'react-toastify';

const percentageFormater = value => {
    try {
        const n = parseFloat(value);
        if(isNaN(n)) return value;
        return `${(n * 100).toFixed(2)} %`
    } catch (e) {
        return value;
    }
}

const moneyFormater = value => {
    try {
        const n = parseFloat(value);
        if(isNaN(n)) return value;
        return `$ ${n.toFixed(2)}`
    } catch (e) {
        return value;
    }
}

const wholeNumberFormater = value => {
    try {
        const n = parseFloat(value);
        if(isNaN(n)) return value;
        return `${Math.round(n)}`
    } catch (e) {
        return value;
    }
}

const FetchForLater = ({queryId, profile, start, end, name, args, setTable, // skip, extra, rows, autoFormat, displayName
}) => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '25wv',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const fetchForLater = async () => {
        const promise = async () => {
            if(!profile || !start || !end) {
                console.log(`${!profile} || ${!start} || ${!end}`);
                console.log(`${profile} || ${start} || ${end}`);
                toast.error('Please select an account and date range');
                return;
            }
            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    query_id: queryId,
                    profile, 
                    start, 
                    end, 
                    args: args || {},
                })
            }
            const response = await fetch('/query-table-for-later', options);
            let data = await response.json();
        }
        toast.promise(promise(), {
            pending: 'Fetching for later',
            success: 'Fetched for later',
            error: 'Failed to fetch for later'
        })
    }

    const getTable = row => {
        let id;
        const promise = async () => {
            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({fetch_id: id})
            }
            const response = await fetch('/get-query-table-for-later', options);
            let data = await response.json();
            if(setTable && data.table) setTable(data.table);
        }
        if(row?.id) {
            id = row.id;
            toast.promise(promise(), {
                pending: 'Fetching',
                success: 'Fetched',
                error: 'Failed to fetch'
            })
        } else {
            toast.error('Invalid fetch id');
        }
    }
    
    return <>
        <button className='btn' onClick={fetchForLater}>Fetch for Later</button>
        <button className='btn' onClick={handleOpen}>Past fetches</button>
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <QueryTable
                    name={`Fetch for later ${name}`}
                    queryId='fetch-for-later-queue'
                    profile={profile} 
                    start={start} 
                    end={end}
                    skip={['id']}
                    args={{'query_id': queryId}}
                    extra={[{field: 'open', headerName: 'Created At', width: 150, 
                        // valueFormatter: (value) => value,
                        renderCell: params=>(params.row.status !== 'done' ? '' : <button onClick={()=>getTable(params.row)}> Get </button>)}]}
                    showCancel
                />
            </Box>
        </Modal>
    </>
}

const QueryTable = ({queryId, profile, start, end, skip, extra, rows, name, args, autoFormat, displayName, showFetchForLater, showCancel}) => {
    const [loading, setLoading] = useState(false);
    const [headers, setHeaders] = useState([]);
    const [table, setTable] = useState([]);
    const [downloadTable, setDownloadTable] = useState([]);

    const moneyTokens = ['Sales', 'Spend', 'AOV'];
    const wholeNumberTokens = ['Impressions', 'Clicks', 'Orders', 'Purchases', 'Volumne'];
    const percentTokens = ['TACOS', 'TACoS', 'ROAS', 'ACOS', 'ACoS', 'CVR', 'CR', 'CTR'];

    const controller = useRef(null);

    useEffect(()=>{
        controller.current = new AbortController();
        if(!rows) fetchTable(controller);
        else setupTable(rows)
        return ()=>cancel();
    }, [queryId, profile, start, end, rows, args]);


    const cancel = () => {
        try {
            controller.current.abort();
        } catch (e) {
            console.log(e);
        }
        setLoading(false);
    }


    const setupTable = async data => {
        if(data.length) {
            cancel()
            setDownloadTable(data);
            let keys = Object.keys(data[0]);
            if (headers.length === 0 || headers.map(row=>row.field).filter(f=>!keys.includes(f)).length > 0) {
                let cols = keys
                    .filter(k=>!Array.isArray(skip) ? true : !skip.includes(k))
                    .map(k=>({field: k, headerName: k, width: 150}));
                if(extra) cols = cols.concat(extra);
                if(autoFormat) {
                    if(typeof autoFormat === 'function') cols = autoFormat(cols, data);
                    else {
                        cols.forEach((col, i) => {
                            const tokens = `${col.field}`.split(' ').map(t=>t.trim());
                            if(tokens.some(t=>moneyTokens.includes(t))){
                                cols[i].valueFormatter = moneyFormater;
                            }
                            if(tokens.some(t=>wholeNumberTokens.includes(t))){
                                cols[i].valueFormatter = wholeNumberFormater;
                            }
                            if(tokens.some(t=>percentTokens.includes(t))) {
                                cols[i].valueFormatter = percentageFormater;
                            }
                        });
                    }
                }
                setHeaders(cols);
            }
            if(!keys.includes('id')) {
                data = data.map((row, i) => ({id: i, ...row, reset: row}))
            }
        }
        setTable(data);
        setLoading(false);
    }

    const fetchTable = async (controller) => {
        if(!profile || !start || !end) {
            console.log(`${!profile} || ${!start} || ${!end}`);
            console.log(`${profile} || ${start} || ${end}`);
            toast.error('Please select an account and date range');
            return;
        }
        setLoading(true);
        try {
            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    query_id: queryId,
                    profile, 
                    start, 
                    end, 
                    args: args || {},
                }),
                ...(!controller ? {} : {signal: controller.signal})
            }
            const response = await fetch('/query-table', options);
            let data = await response.json();
            console.log('/query-table', data);
            if(data.table) {
                data = data.table;
                setupTable(data);
            }
        } catch (e) {
            console.log(e);
        }
        setLoading(false);
    }

    return <div>
        {/* <TextField label='Spend Mercy' type='number' value={spendMercy} onChange={e=>setSpendMercy(e.target.value)}/> */}
        {/* <br/><br/> */}
        {!displayName ? null : <h2>{name}</h2>}
        {rows ? null : <><button className='btn' onClick={fetchTable}>Fetch</button></>}
        {!showCancel ? null : <button className='btn' onClick={cancel}>Cancel</button>}
        <DataTable name={name || queryId || 'Junglr'} headers={headers} table={table} loading={loading} downloadTable={downloadTable}/>
        {!showFetchForLater ? null : <FetchForLater queryId={queryId} profile={profile} start={start} end={end} name={name} args={args} setTable={setupTable}/>}
    </div>
}

export default QueryTable;





