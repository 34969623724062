import {useState, useEffect} from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import DataTable from "../../../Table";
import { toast } from 'react-toastify';


const PrepareBudgets = ({profile, start, end}) => {
    const [loading, setLoading] = useState(false);
    const [headers, setHeaders] = useState([]);
    const [table, setTable] = useState([]);
    const [downloadTable, setDownloadTable] = useState([]);

    const [targetACoS, setTargetACoS] = useState('.35');
    const [orderThreshold, setOrderThreshold] = useState('1');
    const [budgetIncrease, setBudgetIncrease] = useState('5.00');
    const [spendMercy, setSpendMercy] = useState('5.00');

    const fetchTable = async () => {
        if(!profile || !start || !end 
            || isNaN(Number(targetACoS)) || isNaN(parseInt(orderThreshold))
            || isNaN(Number(budgetIncrease)) || isNaN(Number(spendMercy))) {
            console.log(`${!profile} || ${!start} || ${!end} || ${isNaN(Number(targetACoS))}`);
            toast.error('Please select an account, target ACoS and date range');
            return;
        }
        setLoading(true);
        try {
            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    profile, 
                    start, 
                    end, 
                    target_acos: Number(targetACoS),
                    order_threshold: parseInt(orderThreshold),
                    spend_buffer: Number(budgetIncrease),
                    spend_mercy: Number(spendMercy)
                })
            }
            const response = await fetch('/prepare-budget', options);
            let data = await response.json();
            console.log('prepare-budget', data);
            if(data.table) {
                data = data.table;
                if(data.length) {
                    setDownloadTable(data);
                    let keys = Object.keys(data[0]);
                    if (headers.length === 0 || headers.map(row=>row.field).filter(f=>!keys.includes(f)).length > 0) {
                        setHeaders(keys.map(k=>({field: k, headerName: k, width: 150})));
                    }
                    if(!keys.includes('id')) {
                        data = data.map((row, i) => ({id: i, ...row, reset: row}))
                    }
                }
                setTable(data);
            }
        } catch (e) {
            console.log(e);
        }
        setLoading(false);
    }

    return <div>
        <h2>Prepare Budgets</h2>
        {/* <label htmlFor='target_acos'>Target ACoS</label>
        <input id='target_acos' type="number" value={targetACoS} onChange={e=>{
            setTargetACoS(e.target.value);
            console.log(e.target.value, typeof e.target.value);
        }}/> */}
        <TextField label='ACoS Cut Off' type='number' value={targetACoS} onChange={e=>setTargetACoS(e.target.value)}/>
        <TextField label='Order Threshold' type='number' value={orderThreshold} onChange={e=>setOrderThreshold(e.target.value)}/>
        <TextField label='Budget Increase above Max Spend' type='number' value={budgetIncrease} onChange={e=>setBudgetIncrease(e.target.value)}/>
        <TextField label='Spend Mercy' type='number' value={spendMercy} onChange={e=>setSpendMercy(e.target.value)}/>
        <br/><br/>
        <button className='btn' onClick={fetchTable}>Fetch</button>
        <DataTable name='Prepare Budgets' headers={headers} table={table} loading={loading} downloadTable={downloadTable}/>
    </div>
}

export default PrepareBudgets;





