import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dashboard from './components/Dashboard';
// import './App.css';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

// const darkTheme = createTheme({
//   palette: {
//     mode: 'dark',
//   },
// });

const getPermissions = () => {
  let _permissions = sessionStorage.getItem('__permissions');
  try {
    _permissions = JSON.parse(_permissions);
    _permissions.scope = s=>_permissions.user.scopes.includes(s)
  } catch(e) {
    _permissions = {
      user: null,
      auth: false,
      scopes: ['none'],
      scope: s=>false,
      logginNeeded: false,
    };
  }
  return _permissions;
}

const usePermisions = () => {
  
  const [permissions, setPermissions] = useState(getPermissions());

  const fetchUser = async () => {
    try {
      const response = await fetch('/api/user', {
        method: 'GET',
        credentials: 'include' // Equivalent to axios's withCredentials: true
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const data = await response.json();
      
      const newPermissions = {
        user: data, 
        scopes: data.scopes, 
        auth: data.scopes.includes('auth'),
        scope: null,
      };
      sessionStorage.setItem('__permissions', JSON.stringify(newPermissions))
      newPermissions.scope = s=>data.scopes.includes(s);

      setPermissions(newPermissions);

      
    } catch (error) {
      console.error('Error fetching user:', error);
      setPermissions(prev=>{
        return {
          user: null,
          auth: false,
          scopes: ['none'],
          scope: s=>false,
          logginNeeded: true,
        }
      });
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  return [permissions, fetchUser];
}

function App() {
  const [permissions, check] = usePermisions();
  const [notAuthorized, setNotAuthorized] = useState(false);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState({id: 'none', value: false});

  const v = localStorage.getItem('darkMode');
  const [darkMode, setDarkMode] = useState(v ? v === 'true' : true);

  const handleDarkMode = v => {
    localStorage.setItem('darkMode', v ? 'true' : 'false');
    setDarkMode(v);
  };

  // useEffect(() => {
  //   fetchUser();
  // }, []);

  // useEffect(() => {
    
  // }, [user]);

  // const fetchUser = async () => {
  //   try {
  //     const response = await fetch('/auth/user', {
  //       method: 'GET',
  //       credentials: 'include' // Equivalent to axios's withCredentials: true
  //     });
  //     if ((await response.json()).detail.startsWith('Not Authorized User')) {
  //       setNotAuthorized(true);
  //     }
  //     if (!response.ok) {
  //       throw new Error(`HTTP error! Status: ${response.status}`);
  //     }
  
  //     const data = await response.json();
  //     setUser(data);
  //   } catch (error) {
  //     console.error('Error fetching user:', error);
  //     setLoading(false);
  //   }
  // };

  const handleLogin = () => {
    window.location.href = '/auth/login';
  };

  if(permissions.user && !permissions.auth) {
    return <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '100vw'}}>
        <div className='dash' style={{ 
          justifyContent: 'center', 
          alignItems: 'center', 
          // backgroundColor: 'pink',
          padding: 20,
          borderRadius: 10,
          boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)'
        }}>
          <h2 style={{marginBottom: 15}}>Not a Junglr? </h2>
          <p> Maybe Junglr.com? If you are, contact us. </p>
          <p style={{marginBottom: 20, marginTop: 5}}>{`email: ${permissions?.user?.email}`}</p>
          
          <button className='btn' style={{margin: 20}} onClick={()=>{window.location.href = 'https://junglr.app';}}>Go Home</button>

          <button className='btn' style={{margin: 20}} onClick={async () => {
            toast.promise(
              fetch('/request-access', {
                method: 'GET',
                credentials: 'include'
              }),
              {
                pending: 'Sending Request',
                success: 'Thanks! We\'ll get back to you soon.',
                error: 'Error Sending Request',
                hideProgressBar: true,
              }
            );
          }}>Request Access </button>

          {/* <button>Loggout out and Sign in</button> */}
        </div>
        <ToastContainer hideProgressBar/>
      </div>
      return <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '100vw'}}>
        <h3>Not Authorized </h3>
      </div>
    
  }
  
  return (
    <ThemeProvider theme={createTheme({
         palette: (!darkMode ? {} : {
           mode: 'dark',
         }),
       })}>
      <CssBaseline />
    <div>
      {permissions.user && permissions.auth? ( 
        <Dashboard permissions={permissions} refreshPermissions={check} user={permissions.user} darkMode={darkMode} setDarkMode={handleDarkMode}/>
      ) : (
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '100vw'}}>
          {!permissions.logginNeeded 
            ? <p>Loading...</p> 
            : <button className='btn' onClick={handleLogin}>Login with Google</button>
          }
        </div>
      )}
      <ToastContainer 
        closeOnClick
        autoClose={5000}
        hideProgressBar
        theme={darkMode ? "dark" : "light"}
      />
      {/* <pre>
        {JSON.stringify(permissions, null, 2)}
      </pre> */}
    </div>
    </ThemeProvider>
  );
}

export default App;
