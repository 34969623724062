import * as React from 'react';
import { useGridApiContext } from '@mui/x-data-grid';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';

import Box from '@mui/material/Box';
import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/material/styles';

export const COUNTRY_ISO_OPTIONS = [
  { value: 'DE', code: 'DE', label: 'Germany', phone: '49' },
  { value: 'ES', code: 'ES', label: 'Spain', phone: '34' },
  { value: 'FR', code: 'FR', label: 'France', phone: '33' },
  { value: 'GB', code: 'GB', label: 'United Kingdom', phone: '44' },
];

const Country = React.memo(function Country(props) {
  const { value } = props;

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        '&  > img': {
          mr: 0.5,
          flexShrink: 0,
          width: '20px',
        },
      }}
    >
      {/* <img
        loading="lazy"
        width="20"
        src={`https://flagcdn.com/w20/${value.code.toLowerCase()}.png`}
        srcSet={`https://flagcdn.com/w40/${value.code.toLowerCase()}.png 2x`}
        alt=""
      /> */}
      <Box component="span" sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
        {value.id}
      </Box>
    </Box>
  );
});

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  height: '100%',
  [`& .${autocompleteClasses.inputRoot}`]: {
    ...theme.typography.body2,
    padding: '1px 0',
    height: '100%',
    '& input': {
      padding: '0 16px',
      height: '100%',
    },
  },
}));

export const GetDropDownFunction = (options) => {
    function EditCountry(props) {
    const { id, value, field, onChange } = props;

    const apiRef = useGridApiContext();

    const handleChange = React.useCallback(
        async (event, newValue) => {
            await apiRef.current.setEditCellValue({ id, field, value: newValue }, event);
            apiRef.current.stopCellEditMode({ id, field });
            if (onChange) onChange({ id, field, value: newValue });
        },
        [apiRef, field, id],
    );

    return (
        <StyledAutocomplete
        value={value}
        onChange={handleChange}
        options={options}
        getOptionLabel={(option) => option.id}
        autoHighlight
        fullWidth
        open
        disableClearable
        renderOption={(optionProps, option) => (
            <Box
            component="li"
            sx={{
                '& > img': {
                mr: 1.5,
                flexShrink: 0,
                },
            }}
            {...optionProps}
            key={option.profileId}
            >
            {/* <img
                loading="lazy"
                width="20"
                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                alt=""
            /> */}
            {option.id}
            </Box>
        )}
        renderInput={(params) => (
            <InputBase
            autoFocus
            fullWidth
            id={params.id}
            inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password', // disable autocomplete and autofill
            }}
            {...params.InputProps}
            />
        )}
        />
    );
    }
    return EditCountry;
}

export function renderCountry(params) {
  if (params.value == null) {
    return '';
  }

  return <Country value={params.value} />;
}

// export function renderEditCountry(params) {
//   return <EditCountry {...params} />;
// }