import React, { useState, useEffect } from 'react';
// import { Button } from '@/components/ui/button';
import './Dashboard.css'; // Import CSS styles
import { motion, AnimatePresence } from "framer-motion";

import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
// import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/material/styles';


// Accordion
import Accordion from '@mui/material/Accordion';
// import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import Button from '@mui/material/Button';

//Drawer
import { useTheme } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
// import CssBaseline from '@mui/material/CssBaseline';
// import MuiAppBar from '@mui/material/AppBar';
// import Toolbar from '@mui/material/Toolbar';
// import List from '@mui/material/List';
// import Typography from '@mui/material/Typography';
// import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
// import ListItem from '@mui/material/ListItem';
// import ListItemButton from '@mui/material/ListItemButton';
// import ListItemIcon from '@mui/material/ListItemIcon';
// import ListItemText from '@mui/material/ListItemText';
// import InboxIcon from '@mui/icons-material/MoveToInbox';
// import MailIcon from '@mui/icons-material/Mail';

import { toast } from 'react-toastify';
import { useSessionState } from '../../util/util.js';
import Test from './pages/test/Test';
import Accounts from './pages/accounts/Accounts';
import AsinSplit from './pages/asinSplit/AsinSplit';
import CuttingBleeders from './pages/cuttingBleeders/CuttingBleeders';
import MyJobs from './pages/MyJobs/MyJobs';
import PrepareBudgets from './pages/PrepareBudgets/PrepareBudgets';
import SQPRSrapping from './pages/SQPRSrapping/index.js';
import VpnUsers from './pages/VpnUsers/VpnUsers';
import SQPR from './pages/SQPR/SQPR.js';
import TargetAsinMultiDate from './pages/TargetAsinMultiDate.js';


import '../react-date-range/styles.scss'; // main style file
import '../react-date-range/theme/default.scss'; // theme css file

// import dayjs from 'dayjs';
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { DateRange } from '../react-date-range';
import { Modal, TextField } from '@mui/material';

const drawerWidth = 240;

// dayjs('2018-08-08') // parse

// dayjs().format('{YYYY} MM-DDTHH:mm:ss SSS [Z] A') // display

// dayjs().set('month', 3).month() // get & set

// dayjs().add(1, 'year') // manipulate

// dayjs().isBefore(dayjs()) // query

// function DatePickerValue() {
//   const [value, setValue] = React.useState(dayjs('2022-04-17'));

//   return (
//     <LocalizationProvider dateAdapter={AdapterDayjs}>
//       <DemoContainer components={['DatePicker', 'DatePicker']}>
//         <DatePicker label="Uncontrolled picker" defaultValue={dayjs('2022-04-17')} />
//         <DatePicker
//           label="Controlled picker"
//           value={value}
//           onChange={(newValue) => setValue(newValue)}
//         />
//       </DemoContainer>
//     </LocalizationProvider>
//   );
// }

export const formatDate = date => {
  // const offset = date.getTimezoneOffset()
  // date = new Date(date.getTime() - (offset*60*1000))
  // return date.toISOString().split('T')[0];
  const d = new Date(date);
  let month = '' + (d.getMonth() + 1);
  let day = '' + d.getDate();
  const year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [year, month, day].join('-');
}

const fetchProfiles = async () => {
  const endpoint = '/get-profiles';
  const options = {
      method: 'POST',
      credentials: 'include',
      // headers: {
      //     'Content-Type': 'application/json'
      // },
      // body: JSON.stringify(body)
  }
  const response = await fetch(endpoint, options);
  const data = await response.json();
  return data.profiles;
}

const fetchAccounts = async profiles => {
  console.log('profiles', profiles);
  const profileMap = profiles.reduce((acc, profile) => {
      acc[profile.profileId] = profile;
      return acc;
  }, {});

  const endpoint = '/get-accounts';
  const options = {
      method: 'POST',
      credentials: 'include',
  }
  const response = await fetch(endpoint, options);
  const data = await response.json();
  console.log('data', data);
  return data.accounts.map(account => ({ ...account, api: profileMap[account.profileId] }));
}

const editAddAccount = async ({id, name, profileId, sc_link, br_link, sqpr_link, email}) => {
  const endpoint = '/edit-add-account';
  const body = {id, name, profile_id: profileId, sc_link, br_link, sqpr_link, email};
  console.log('body', JSON.stringify(body));
  const options = {
      method: 'POST',
      credentials: 'include',
      headers: {
          'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
  }
  const response = await fetch(endpoint, options);
  console.log('response', response);
  const data = await response.json();
  console.log('editAddAccount data', data);
  // fetchAccounts();
  return data;
}


const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  height: '100%',
  [`& .${autocompleteClasses.inputRoot}`]: {
    ...theme.typography.body2,
    padding: '1px 0',
    height: '100%',
    '& input': {
      padding: '0 16px',
      height: '100%',
    },
  },
}));


const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));


const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme }) => ({
    // background-color: #fafafa;
    // border-radius: 10px;
    // margin: 20px;
    // box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    backgroundColor: '#fafafa',
    ...theme.applyStyles('dark', {
      backgroundColor: '#1e1e1e',
      color: '#e1e1e1'
    }),
    borderRadius: '10px',
    margin: '20px',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth-20}px`,
    variants: [
      {
        props: ({ open }) => open,
        style: {
          transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
          }),
          marginLeft: '20px',
        },
      },
    ],
  }),
);


const SearchDropDown = ({options, defaultValue, itemKey: key, onChange}) => {
  const [query, setQuery] = useState('');
  const [value, setValue] = useState(defaultValue || {});
  const [editing, setEditing] = useState(false)

  const handleChange = (newValue) => {
    console.log('newValue', newValue);
    setValue(newValue);
    if(onChange) onChange(newValue);
  }

  const filter = values => {
    return values.filter(v => ensureString(key ? v[key] : v).toLowerCase().includes(query.toLowerCase()));
  }

  const ensureString = obj => {
    return typeof obj === 'string' ? obj : 'No Value';
  }

  return <div>
    {!editing 
    ? <button className='btn' onClick={()=>setEditing(true)}>
      {ensureString(key ? value[key] : value)}
    </button>
    : <>
      <input autoFocus value={query} onChange={e=>setQuery(e.target.value)}/>
      {filter(options).map(v => 
        <button className='btn' onClick={()=>{handleChange(v);setEditing(false)}}>
          {ensureString(key ? v[key] : v)}
        </button>)}
    </>}
  </div>
}


const Conigurations = ({accounts, currentAccount, setCurrentAccount, currentDateRange, setDateRange}) => {
  const [preview, setPreview] = useState(true);
  const [state, setState] = useState([
    {
      startDate: currentDateRange.start,
      endDate: currentDateRange.end,
      key: 'selection'
    }
  ]);
  const [value, setValue] = useState(null);
  

  const handleChange = async (event, newValue) => {
    setValue(newValue);
  }
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return <div style={{position: 'absolute', top: 0, right: 0, zIndex: 99}}>
    <button style={{margin: 5}} className='btn' onClick={handleOpen}>
    {!currentAccount?.name && !state[0]?.startDate && !state[0]?.endDate ? 'Account Settings' : ''}
        {currentAccount?.name}
        {state[0]?.startDate ? ` | start: ${state[0]?.startDate?.toLocaleDateString()}` : ''}
        {state[0]?.endDate ? ` | end: ${state[0]?.endDate?.toLocaleDateString()}` : ''}
    </button>
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 'auto',
        border: 'none',
        bgcolor: 'background.paper',
        borderRadius: '5px',
        // boxShadow: 24,
        p: 4,
      }}>
        {/* <SearchDropDown 
              options={accounts} 
              itemKey='name' 
              defaultValue={currentAccount}
              onChange={v=>setCurrentAccount(v)}
            /> */}
            <Autocomplete
              disablePortal
              options={accounts.map(a=>a?.name)}
              value={currentAccount?.name}
              onChange={(event, newValue) => {
                if(newValue) setCurrentAccount(accounts.find(a=>a?.name===newValue))
              }}
              sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="Account" />}
            />
          <br/>
          <DateRange
            editableDateInputs={true}
            onChange={item => {
              setState([item.selection]);
              console.log('item', item);
              setDateRange({start: item.selection.startDate || null, end: item.selection.endDate || null});
            }}
            moveRangeOnFirstSelection={false}
            ranges={state}
          />
      </Box>
    </Modal>
  </div>

  return <div style={{position: 'absolute', top: 0, right: 0, zIndex: 99}}>
    <motion.div
      animate={{
        rotate: 0,
        width: 'auto',
        height: 'auto',
      }}
      whileHover={{
        y: 0,
        height: window.screen.height * .75,
        width: 400,
      }}
      // onClick={()=>setPreview(!preview)}
      onHoverStart={()=>setPreview(false)}
      onHoverEnd={()=>setPreview(true)}
      style={{backgroundColor: 'white', borderRadius: 10, color: 'black'}}
    >
     {preview ? <>
        {!currentAccount?.name && !state[0]?.startDate && !state[0]?.endDate ? <h2>Config</h2> : null}
        <p style={{marginLeft: 10, marginRight: 10}}>
          {currentAccount?.name}
        </p>
        {state[0]?.startDate ? <p style={{marginLeft: 10, marginRight: 10}}> start: {state[0]?.startDate?.toLocaleDateString()}</p> : null}
        {state[0]?.endDate ? <p style={{marginLeft: 10, marginRight: 10}}> end: {state[0]?.endDate?.toLocaleDateString()}</p> : null}
      </>
      : <motion.div
          initial={{opacity: 0}}
          animate={{opacity: 1}}
          exit={{opacity: 0}}
        >
          <div style={{width: 200}}>
            <SearchDropDown 
              options={accounts} 
              itemKey='name' 
              defaultValue={currentAccount}
              onChange={v=>setCurrentAccount(v)}
            />
          </div>
          <br/>
          <DateRange
            editableDateInputs={true}
            onChange={item => {
              setState([item.selection]);
              console.log('item', item);
              setDateRange({start: item.selection.startDate || null, end: item.selection.endDate || null});
            }}
            moveRangeOnFirstSelection={false}
            ranges={state}
          />
        </motion.div>}
    </motion.div>
  </div>
}

const getFirstItem = obj => Object.values(obj)[0]

const Dashboard = ({ permissions, refreshPermissions, user, darkMode, setDarkMode }) => {const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };


  const [accounts, setAccounts] = useState([]);
  const [profiles, setProfiles] = useState([]);
  const [loadingAccounts, setLoadingAccounts] = useState(true);

  // const cacheAccount = localStorage.getItem('currentAccount');
  // const [currentAccount, _setCurrentAccount] = useState(cacheAccount ? JSON.parse(cacheAccount) : null);
  // const setCurrentAccount = v => {
  //   _setCurrentAccount(v);
  //   localStorage.setItem('currentAccount', JSON.stringify(v));
  // }
  const [currentAccount, setCurrentAccount] = useSessionState(null, 'currentAccount');
  // let cacheDateRange = localStorage.getItem('dateRange');
  // if(cacheDateRange) {
  //   cacheDateRange = JSON.parse(cacheDateRange);
  //   cacheDateRange.start = new Date(cacheDateRange.start);
  //   cacheDateRange.end = new Date(cacheDateRange.end);
  // }
  // const [dateRange, _setDateRange] = useState(cacheDateRange ? cacheDateRange : {start: null, end: null});
  // const setDateRange = v => {
  //   _setDateRange(v);
  //   v.start = v.start.toISOString();
  //   v.end = v.end.toISOString();
  //   localStorage.setItem('dateRange', JSON.stringify(v));
  // }
  const encode = v => {
    return JSON.stringify({
      start: !v.start ? null : v.start.toISOString(),
      end: !v.end ? null : v.end.toISOString(),
    });
  }
  const decode = v => {
    v = JSON.parse(v);
    if(v.start) v.start = new Date(v.start);
    if(v.end) v.end = new Date(v.end);
    return v;
  }
  const [dateRange, setDateRange] = useSessionState({start: null, end: null}, 'dateRange', encode, decode);

  const getAccounts = async () => {
    await fetchProfiles()
      .then(profiles => {
          setProfiles(profiles);
          return fetchAccounts(profiles);
      })
      .then(accounts => {
          console.log('accounts', accounts);
          setAccounts(accounts);
          setLoadingAccounts(false);

          if(currentAccount?.id) {
            setCurrentAccount(accounts.find(account => account.id === currentAccount.id));
          }
      });
  }

  const [openSection, setOpenSection] = useState(null);
  const pages = {
    'Settings': {
        'Accounts': 'Account Settings',
        // 'Test Sheet': <iframe src="https://docs.google.com/spreadsheets/d/e/2PACX-1vQ5Kx1xFw7ckC78UkWVBDyIb2xAReUXaIo93b2d1b_iJjac1_xCSKeXgEeVYHaJofsmdb_Xvkxcsz24/pubhtml?gid=1621548399&amp;single=true&amp;widget=true&amp;headers=false" width='95%' height='95%'></iframe>,
        // 'Test Page': <Test refreshPermissions={refreshPermissions}/>,
        // 'Page 3': <div>Page 3 Content</div>,
    },
    'Tables': {
      'SQPR': 'SQPR',
      'Target Multi-Date with ASINs': 'TargetAsinMultiDate',
    },
    'Beta': {
        'STR by ASIN': 'ASIN Split',
        'Cutting Bleeders': 'Cuttting Bleeders',
        'My Jobs': <MyJobs/>,
        'Prepare Budgets': 'Prepare Budgets',
        'SQPR Srapping': 'SQPR Srapping',
        'VPN Users': 'VPN Users',
    }
  };
  if(permissions.scope('dev')) {
    pages['Dev'] =  {
        'Bugs': <div>Bugs</div>,
    }
  }
  const [currentPage, setCurrentPage] = useState(null);

  useEffect(() => {
      getAccounts();
  }, []);

  const editOrAdd = async params => {
    await editAddAccount(params);
    await getAccounts();
  }

  // Toggle Accordion Section
  const toggleSection = (section) => {
    setOpenSection(openSection === section ? null : section);
  };

  
  return <div className={`dashboard ${darkMode ? 'dark' : ''} dash`}>
    <Conigurations 
      accounts={accounts} 
      currentAccount={currentAccount} 
      setCurrentAccount={setCurrentAccount}
      currentDateRange={dateRange}
      setDateRange={setDateRange}
    />
    <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
      {/* <aside className="sidebar"> */}
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 15}}>
            <h2 className="sidebar-title">Junglr</h2>
            <button className='btn' style={{marginLeft: 10, fontSize: 12, }} onClick={() => setDarkMode(!darkMode)}>
                {darkMode ? 'Light Mode' : 'Dark Mode'}
            </button>
        </div>
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </DrawerHeader>
          {/* <Divider /> */}
        {/* <Divider /> */}
        {/* <div className="accordion">
            {Object.keys(pages).map((sect, i)=>{
                return <div className="accordion-section">
                    <div className="accordion-header" onClick={() => toggleSection(i)}>
                    <h3>{sect}</h3>
                    </div>
                    <AnimatePresence initial={false}>
                        {openSection === i && (
                            <motion.div 
                                className="accordion-content"
                                // Tween, Spring or Inertia
                                initial={{ scaleY: 0, y: '-50%', height: 0 }}
                                animate={{ scaleY: 1, y: 0, height: 'auto', margin: 25 }}
                                exit={{ scaleY: 0, y: '-50%', height: 0, padding: 0, margin: 0, color: 'transparent' }}
                                transition={{
                                    type: "spring",
                                    stiffness: 260,
                                    damping: 20, //, duration: 0.4, bounce: 0
                                }}
                            >
                                <ul>
                                    {Object.keys(pages[sect]).map((page, j)=>{
                                        return <li onClick={()=>setCurrentPage(pages[sect][page])}>{page}</li>
                                    })}
                                </ul>
                            </motion.div>
                        )}
                    </AnimatePresence>
                </div>
            })}
        </div> */}
        <div>
        {Object.keys(pages).map((sect, i)=>{
                return <Accordion key={`${sect}-${i}`} style={{marginLeft: 15, marginRight: 15}}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    {sect}
                  </AccordionSummary>
                  <AccordionDetails>
                  {/* <List>
                    {Object.keys(pages[sect]).map((page, j)=>{
                        return <ListItem key={`${page}-${j}`} disablePadding>
                        <ListItemButton onClick={()=>setCurrentPage(pages[sect][page])}>
                          <ListItemIcon>
                            {i % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                          </ListItemIcon>
                          <ListItemText primary={page} />
                        </ListItemButton>
                      </ListItem>
                    })}
                  </List> */}
                  <div 
                      className="accordion-content2"
                  >
                      <ul>
                          {Object.keys(pages[sect]).map((page, j)=>{
                              return <li  key={`${page}-${j}`} onClick={()=>setCurrentPage(pages[sect][page])}>{page}</li>
                          })}
                      </ul>
                  </div>
                  </AccordionDetails>
                </Accordion>
            })}
        </div>
      
    {/* </aside> */}
    </Drawer>

    {/* <main className="content"> */}
    <Main open={open}>
        <header className="content-header">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={[
              {
                mr: 2,
              },
              open && { display: 'none' },
            ]}
          >
            <MenuIcon />
          </IconButton>
          {!!currentPage ? null : <h1>Welcome, {user.name}</h1>}
          {/* <button onClick={() => setDarkMode(!darkMode)}>
            {darkMode ? 'Light Mode' : 'Dark Mode'}
          </button> */}
        </header>
        <section>
          {/* Content for the selected page will go here */}
          {currentPage === 'Account Settings'
          ? <Accounts accounts={accounts} profiles={profiles} loading={loadingAccounts} refresh={getAccounts} editOrAdd={editOrAdd}/>
          : currentPage === 'ASIN Split'
          ? <AsinSplit account={currentAccount} start={dateRange.start} end={dateRange.end}/>
          : currentPage === 'Prepare Budgets'
          ? <PrepareBudgets profile={currentAccount?.profileId} start={formatDate(dateRange.start)} end={formatDate(dateRange.end)}/>
          : currentPage === 'Cuttting Bleeders'
          ? <CuttingBleeders profile={currentAccount?.profileId} start={formatDate(dateRange.start)} end={formatDate(dateRange.end)}/>
          : currentPage === 'SQPR Srapping'
          ? <SQPRSrapping accounts={accounts} profile={currentAccount?.profileId} start={formatDate(dateRange.start)} end={formatDate(dateRange.end)}/>
          : currentPage === 'VPN Users'
          ? <VpnUsers accounts={accounts} profile={currentAccount?.profileId} start={formatDate(dateRange.start)} end={formatDate(dateRange.end)}/>
          : currentPage === 'SQPR'
          ? <SQPR accounts={accounts} profile={currentAccount?.profileId} start={formatDate(dateRange.start)} end={formatDate(dateRange.end)}/>
          : currentPage === 'TargetAsinMultiDate'
          ? <TargetAsinMultiDate accounts={accounts} profile={currentAccount?.profileId} start={formatDate(dateRange.start)} end={formatDate(dateRange.end)}/>
          : currentPage}
        </section>
      {/* </main> */}
    </Main>
  </div>

  return (
    <div className={`dashboard ${darkMode ? 'dark' : ''}`}>
      <aside className="sidebar">
        <h2 className="sidebar-title">Dashboard</h2>
        <div className="accordion">
          {/* Section 1 */}
          <div className="accordion-section">
            <div className="accordion-header" onClick={() => toggleSection(1)}>
              <h3>Section 1</h3>
            </div>
            {openSection === 1 && (
              <div className="accordion-content">
                <ul>
                  <li onClick={()=>setCurrentPage('Page 1')}>Page 1</li>
                  <li onClick={()=>setCurrentPage('Page 2')}>Page 2</li>
                  <li onClick={()=>setCurrentPage('Page 3')}>Page 3</li>
                </ul>
              </div>
            )}
          </div>

          {/* Section 2 */}
          <div className="accordion-section">
            <div className="accordion-header" onClick={() => toggleSection(2)}>
              <h3>Section 2</h3>
            </div>
            {openSection === 2 && (
              <div className="accordion-content">
                <ul>
                  <li onClick={()=>setCurrentPage('Page 1')}>Page 1</li>
                  <li onClick={()=>setCurrentPage('Page 2')}>Page 2</li>
                  <li onClick={()=>setCurrentPage('Page 3')}>Page 3</li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </aside>

      <main className="content">
        <header className="content-header">
          <h1>Welcome, {user.name}</h1>
          <button onClick={() => setDarkMode(!darkMode)}>
            {darkMode ? 'Light Mode' : 'Dark Mode'}
          </button>
        </header>
        <section>
          {/* Content for the selected page will go here */}
          {JSON.stringify(currentPage)}
        </section>
      </main>
    </div>
  );
};


// const Dashboard = ({user, darkMode, setDarkMode}) => {
//     const [files, setFiles] = useState([]);

//     const fetchDriveFiles = async () => {
//         try {
//           const response = await fetch('/api/drive/files', {
//             method: 'GET',
//             credentials: 'include' // Equivalent to axios's withCredentials: true
//           });
          
//           if (!response.ok) {
//             throw new Error(`HTTP error! Status: ${response.status}`);
//           }
      
//           const data = await response.json();
//           setFiles(data.files);
//         } catch (error) {
//           console.error('Error fetching Drive files:', error);
//         }
//       };

//     return <div>
//         <p>Welcome, {user.name}!</p>
//         <button onClick={fetchDriveFiles}>Fetch Drive Files</button>
//         <ul>
//         {files.map(file => (
//             <li key={file.id}>{file.name}</li>
//         ))}
//         </ul>
//     </div>
// }



export default Dashboard;
