import React, { useState, useEffect } from "react";
import DataTable from "../../../Table";
import { v1 as uuidv1, v4 as uuidv4 } from 'uuid';
import { toast } from "react-toastify";
import { gridStringOrNumberComparator, useGridApiContext } from '@mui/x-data-grid';
import { TextField } from "@mui/material";
import { COUNTRY_ISO_OPTIONS, renderCountry, renderEditCountry, GetDropDownFunction } from "./cell-profiles";


const copy = j => JSON.parse(JSON.stringify(j));




const Accounts = ({accounts, profiles, loading, refresh, editOrAdd: edit}) => {
    const [newAccountName, setNewAccountName] = useState(null);

    const onProfileChange = ({ id, field, value: newProfile }) => {
        console.log('newProfile', newProfile);
        console.log('field', field);
        console.log('id', id);
        const _row = accounts.find(account => account.id === id);
        _row.profileId = newProfile.profileId;
        const row = copy(_row);
        if(newProfile.profileId && row) {
            delete row.api;
            const promise = edit({  // editAddAccount({
                ...row,
                profileId: newProfile.profileId,
            });
            toast.promise(promise, {
                pending: 'Updating account',
                success: 'Account updated successfully',
                error: 'Error updating account'
            });
        }
    }

    const UrlInput = ({params}) => {
        const { id, value, field, ...rest } = params;

        const [_value, setValue] = useState(value || '');
        let delay;

        const apiRef = useGridApiContext();

        const saveApi = async () => {
            // use `_value`
            console.log('_value', _value);
            console.log('rest', rest);
            const _row = accounts.find(account => account.id === id);
            _row[field] = _value;
            const row = copy(_row);
            if(_value && row) {
                delete row.api;
                const promise = edit({  // editAddAccount({
                    ...row,
                    [field]: _value,
                });
                toast.promise(promise, {
                    pending: 'Updating account',
                    success: 'Account updated successfully',
                    error: 'Error updating account'
                },
                {
                    autoClose: 500,
                });
            }
        }

        useEffect(() => {
            delay = setTimeout(() => {if(_value) {saveApi()} }, 2000);
            return () => clearTimeout(delay)
        }, [_value])
    
        const keyDown = (e)=>{
            if (e.key === "Enter") {
                clearTimeout(delay);
                saveApi()
            }
        }

        const handleChange = React.useCallback(
            (event) => {
                const newValue = event.target.value;
                setValue(newValue);
                apiRef.current.setEditCellValue(
                    { id, field, value: newValue, debounceMs: 200 },
                    event,
                );
            },
            [apiRef, field, id],
        );
    
        return <TextField 
            label={field}
            value={_value}
            onChange={handleChange}
            autoFocus
            onKeyDown={keyDown}
        />;
    }

    const columns = [
        // { field: 'id', headerName: 'ID', width: 70 },
        { field: 'name', headerName: 'Account Name', width: 150 },
        // { field: 'api', headerName: 'API ID', width: 70 },
        {
            field: 'api',
            headerName: 'API ID',
            type: 'singleSelect',
            valueOptions: profiles,
            valueFormatter: (value) => value?.id,
            renderCell: renderCountry,
            renderEditCell: params=>{
                const El = GetDropDownFunction(profiles);
                return <El {...{...params, onChange: onProfileChange}} />;
            },
            sortComparator: (v1, v2, param1, param2) =>
              gridStringOrNumberComparator(v1.id, v2.id, param1, param2),
            width: 150,
            editable: true,
        },
        {
            field: 'sc_link',
            headerName: 'Seller Central',
            // valueFormatter: (value) => value,
            // renderCell: params=><p>{params.value || 'none'}</p>,
            renderEditCell: params=><UrlInput params={params}/>,
            width: 150,
            editable: true,
        },
        {
            field: 'sqpr_link',
            headerName: 'Scrapper Link',
            // valueFormatter: (value) => value,
            // renderCell: params=><p>{params.value || 'none'}</p>,
            renderEditCell: params=><UrlInput params={params}/>,
            width: 150,
            editable: true,
        },
        {
            field: 'email',
            headerName: 'Email Login',
            // valueFormatter: (value) => value,
            // renderCell: params=><p>{params.value || 'none'}</p>,
            renderEditCell: params=><UrlInput params={params}/>,
            width: 150,
            editable: true,
        },
    ];

    const openQuickLinks = () => {
        window.open(
            'https://docs.google.com/spreadsheets/d/1z7LDP1mzWCcpJ9wCzQtYrJtdPtebF24K7HSNcTHESnA/edit?gid=2081755701#gid=2081755701', 
            '_blank'
        );
    }

    const handleAddAccount = async () => {
        if (!newAccountName) {
            console.log('no name')
            toast.error('Please enter a name for the new account');
            return;
        }
        setNewAccountName('');
        const promise = async () => {
            const r = await edit({  // editAddAccount({
                id: `${uuidv1()}-${uuidv4()}`,
                name: newAccountName,
                profileId: null,
                sc_link: null,
                br_link: null,
                sqpr_link: null,
                email: null,
            });
            try {
                await refresh();  // getAccounts();
                console.log('data', r);
            } catch(e) {
                console.log('error', e);
                throw e;
            }
        }

        toast.promise(promise, {
            pending: 'Adding account',
            success: 'Account added successfully',
            error: 'Error adding account'
        });
    }

    

    return (
        <div>
            <h1>Accounts</h1>
            {/* <input value={newAccountName} onChange={e => setNewAccountName(e.target.value)} /> */}
            <TextField label='New Account Name' value={newAccountName} onChange={e => setNewAccountName(e.target.value)} />
            <button className='btn' onClick={handleAddAccount}>Add Account</button>
            <button className='btn' onClick={openQuickLinks}>Open Quick Links</button>
            <br/><br/>
            
            <DataTable 
                headers={columns} 
                table={accounts} 
                loading={loading}
            />
        </div>
    );
}


export default Accounts;