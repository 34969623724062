import React, { useState } from 'react';
import { TextField, Switch, FormGroup, FormControl, FormControlLabel, Box, Modal } from '@mui/material';
import QueryTable from '../../../DatabaseTable';
import {toast} from 'react-toastify';


const LoomToolTip = ({title='How to use the tool', loomLink, loomEmbededLink}) => {
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const openLoom = () => {
        window.open(
            loomLink,
            '_blank'
        );
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '75%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    return <>
        {/* <button className='btn' onClick={handleFetchAccounts}>
            Go Through Accounts
        </button> */}
        <button style={{margin: 15}} className='btn' onClick={handleOpen}>
            {title}
        </button>
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
        <Box sx={style}>
            <div style={{position: 'relative', 'paddingBottom': '62.5%', height: 0}}>
                <iframe 
                    src={loomEmbededLink} 
                    frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen 
                    style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}}>
                </iframe>
            </div>
            <button style={{margin: 15}} className='btn' onClick={openLoom}>
                or Open in Loom
            </button>
        </Box>
        </Modal>
    </>
}


const SQPR = ({profile, start, end}) => {
    const [args, setArgs] = useState({
        asin: '',
        query: '',
        like_query: '',
    });

    return <div>
    <div style={{display: 'flex', flexDirection: 'row'}}>
        <h2 style={{marginRight: 15}}>SQPR</h2>

        <LoomToolTip 
            title='In depth use' 
            loomLink='https://www.loom.com/share/ec3ae14e8b8341ea9cde1fb19cb98d34?sid=aac1f158-011f-4eb9-be54-f82a4891f548' 
            loomEmbededLink='https://www.loom.com/embed/ec3ae14e8b8341ea9cde1fb19cb98d34?sid=32c23823-f9e1-4aa0-a50b-35bd23346418'
        />
    </div>
    <br/>
    <TextField id="outlined-basic" label="ASIN" variant="outlined" value={args?.asin || ''} onChange={(e) => setArgs({...args, asin: e.target.value})} />
    <TextField id="outlined-basic" label="Query" variant="outlined" value={args?.query || ''} onChange={(e) => setArgs({...args, query: e.target.value})} />
    <TextField id="outlined-basic" label="Similar Query" variant="outlined" value={args?.like_query || ''} onChange={(e) => setArgs({...args, like_query: e.target.value})} />

    
    <div style={{width: '70vw', marginTop: 25}}>
        <QueryTable 
            displayName
            autoFormat
            name='Junglr Search Query Performance Report'
            queryId='sqpr' profile={profile} start={start} end={end}
            args={{
                ...(!args.asin ? {} : {asin: args.asin}),
                ...(!args.query ? {} : {query: args.query}),
                ...(!args.like_query ? {} : {like_query: args.like_query}),
            }}
        //   skip={['key', 'email', 'profile']}
        //   extra={[col]}
        />
        <QueryTable 
            displayName
            autoFormat
            name='Seart Terms by ASINs'
            queryId='sqpr-st' profile={profile} start={start} end={end}
            args={{
                ...(!args.asin ? {} : {asin: args.asin}),
                ...(!args.query ? {} : {query: args.query}),
                ...(!args.like_query ? {} : {like_query: args.like_query}),
            }}
        //   skip={['key', 'email', 'profile']}
        //   extra={[col]}
        />
        <QueryTable 
            displayName
            autoFormat
            name='Seart Terms by ASINs by Week'
            queryId='sqpr-st-per-week' profile={profile} start={start} end={end}
            args={{
                ...(!args.asin ? {} : {asin: args.asin}),
                ...(!args.query ? {} : {query: args.query}),
                ...(!args.like_query ? {} : {like_query: args.like_query}),
            }}
        //   skip={['key', 'email', 'profile']}
        //   extra={[col]}
        />
        <QueryTable 
            displayName
            autoFormat
            name='ASINs'
            queryId='sqpr-only-asin' profile={profile} start={start} end={end}
            args={{
                ...(!args.asin ? {} : {asin: args.asin}),
                ...(!args.query ? {} : {query: args.query}),
                ...(!args.like_query ? {} : {like_query: args.like_query}),
            }}
        //   skip={['key', 'email', 'profile']}
        //   extra={[col]}
        />
    </div>
  </div>
}

export default SQPR;
