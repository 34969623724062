import { useState, useEffect } from 'react';



const useSessionState = (defaultValue, key, encode, decode) => {
    const [value, setValue] = useState(() => {
        const storedValue = sessionStorage.getItem(key);
        console.log(key, storedValue)
        if (storedValue !== null) {
            console.log('decode', decode ? decode(storedValue) : JSON.parse(storedValue))
            return decode ? decode(storedValue) : JSON.parse(storedValue);
        }
        return defaultValue;
        return storedValue !== null ? JSON.parse(storedValue) : defaultValue;
    });

    const overrideSetValue = newValue => {
        console.log('overrideSetValue', newValue)
        if(typeof newValue === 'function') newValue = newValue(value)
        setValue(newValue);
        sessionStorage.setItem(key, encode ? encode(value) : JSON.stringify(value));
    };

    useEffect(() => {
        // sessionStorage.setItem(key, JSON.stringify(value));
        sessionStorage.setItem(key, encode ? encode(value) : JSON.stringify(value));
        console.log('set', key, value)
    }, [key, value, encode]);

    return [value, overrideSetValue];
}


export {useSessionState};