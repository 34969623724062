import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import DataTable from "../../../Table";

const formatDate = date => {
    // const offset = date.getTimezoneOffset()
    // date = new Date(date.getTime() - (offset*60*1000))
    // return date.toISOString().split('T')[0];
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}

const AsinSplit = ({account, start, end}) => {
    const [table, setTable] = useState([]);
    const [loading, setLoading] = useState(false);
    const [headers, setHeaders] = useState([]);

    const fetchTable = async () => {
        if(!account.profileId || !start || !end) {
            toast.error('Please select an account and date range');
            return;
        }
        setLoading(true);
        try {
            console.log('fetching', account.profileId, formatDate(start), formatDate(end));
            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({profile: `${account.profileId}`, start: formatDate(start), end: formatDate(end)})
            }
            const response = await fetch('/asin-split', options);
            let data = await response.json();
            console.log('data', data);
            if(data.table) {
                data = data.table;
                if(data.length) {
                    let keys = Object.keys(data[0]);
                    setHeaders(keys.map(k=>({field: k, headerName: k, width: 70})));
                    if(!keys.includes('id')) {
                        data = data.map((row, i) => ({id: i, ...row}))
                    }
                }
                setTable(data);
            }
        } catch (e) {
            console.log(e);
        }
        setLoading(false);
    }

    return <div>
        <h2>STR by ASIN</h2>
        <button className='btn' onClick={fetchTable}>Fetch</button>
        <DataTable headers={headers} table={table} loading={loading}/>
    </div>
}



export default AsinSplit;





