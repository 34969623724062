import React, { useState } from 'react';
import { TextField, Switch, FormGroup, FormControl, FormControlLabel } from '@mui/material';
import QueryTable from '../../../DatabaseTable';
import {toast} from 'react-toastify';
import { useSessionState } from '../../../../util/util';
import { DateRange } from '../../../react-date-range';
import { formatDate } from '../..';


const encode = v => {
  return JSON.stringify({
    start: !v.start ? null : v.start.toISOString(),
    end: !v.end ? null : v.end.toISOString(),
  });
}
const decode = v => {
  v = JSON.parse(v);
  if(v.start) v.start = new Date(v.start);
  if(v.end) v.end = new Date(v.end);
  return v;
}


const CuttingBleeders = ({profile, start, end}) => {
  const [reportName, setReportName] = useSessionState('', 'cbReportName');
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState("");

  const [cutHighACoS, setCutHighACoS] = useSessionState(false, 'cbCutHighACoS');
  const [cutOffClicks, setCutOffClicks] = useSessionState('8', 'cbCutOffClicks');
  const [cutOffACoS, setCutOffACoS] = useSessionState('0.65', 'cbCutOffACoS');
  const [bidMultiplication, setBidMultiplication] = useSessionState('0.75', 'cbBidMultiplication');

  const [useSecondDateRange, setUseSecondDateRange] = useSessionState(false, 'useCbDateRange');
  const [dateRange, setDateRange] = useSessionState({start: null, end: null}, 'cbDateRange', encode, decode);
  const [_dateRange, _setDateRange] = useState([{startDate: dateRange.start, endDate: dateRange.end, key: 'selection' }]);

  const [type, setType] = useSessionState('file', 'cbType');
  const [table, setTable] = useState([]);

  // Handle file selection
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  // Handle file upload
  const handleUpload = async (event) => {
    toast.promise(upload(event), {
      pending: 'Uploading file...',
      success: 'File uploaded successfully!',
      error: 'Error uploading file.'
    });
  }

  const upload = async event => {
    setUploadStatus('');
    event.preventDefault();
    if (!selectedFile) {
      setUploadStatus("Please select a file first.");
      throw new Error("Please select a file first.");
    }

    if(!reportName) {
      setUploadStatus("Please enter a report name.");
      throw new Error("Please enter a report name.");
    }

    if(isNaN(parseInt(cutOffClicks)) || isNaN(parseFloat(cutOffACoS)) || isNaN(parseFloat(bidMultiplication))) {
      setUploadStatus("Please enter valid numbers.");
      throw new Error("Please enter valid numbers.");
    }

    // Prepare FormData object
    const formData = new FormData();
    // selectedFile.name = reportName;
    formData.append('file', selectedFile, reportName); // "file" should match the FastAPI parameter name
    const json = {
      click_cut_off: parseInt(cutOffClicks),
      use_acos: cutHighACoS,
      acos_cut_off: parseFloat(cutOffACoS),
      cut: parseFloat(bidMultiplication)
    }
    formData.append('json_data', JSON.stringify(json));

    try {
      // Make the POST request to FastAPI
      const response = await fetch('/upload-cut-bleeders-xlsx', {
        method: 'POST',
        credentials: 'include',
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        // setUploadStatus(`File uploaded successfully: ${JSON.stringify(data)}`);
      } else {
        console.error('File upload failed.');
        console.error(response.statusText);
        throw new Error('File upload failed.');
        // setUploadStatus("File upload failed.");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      // setUploadStatus("Error uploading file.");
      throw error;
    }
  };

  const textFieldStyle = {marginBottom: 15}

  const FileUploader = () => {
    return (
      <div>
        <h1>File Upload</h1>
        <form onSubmit={handleUpload}>
          <input type="file" onChange={handleFileChange} />
          <button type="submit">Upload</button>
        </form>
        <p>{uploadStatus}</p>
        <br/><br/>
        <QueryTable 
          queryId='cutting-bleeders-output'
          profile={profile}
          start={start}
          end={end}
          skip={['Email']}
          extra={{
            field: 'open',
            headerName: 'Open',
            // valueFormatter: (value) => value,
            renderCell: params=><button className='btn' onClick={()=>{window.open(params.row.Link, '_blank');}}>Open</button>,
            width: 150,
          }}
        />
      </div>
    );
  };


  const CacheTable = () => {
    const _request = async () => {
      const json = {
        report_name: reportName,
        profile: profile,
        start: start,//.toISOString().split('T')[0],
        end: end,//.toISOString().split('T')[0],
        target_acos: parseFloat(cutOffACoS),
        cut_acos: cutHighACoS,
        clicks: parseInt(cutOffClicks),
        cut: parseFloat(bidMultiplication)
      }
      const body = JSON.stringify(json);
      const response = await fetch('/run-cut-bleeder-job', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
        body: body,
      });
      const data = await response.json();
      // setTable(data);
      console.log('data', data);
    }
    const request = () => toast.promise(_request(), {pending: 'Running...', success: 'Success!', error: 'Error.'});

    const show = async params => {
      const promise = async () => {
        const body = {key: params.row.key};
        const response = await fetch('/get-cut-bleeder-job', {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          credentials: 'include',
          body: JSON.stringify(body),
        });
        const data = await response.json();
        console.log('key data', data);
        if(data.table) {
          setTable(data.table);
        }
      }
      toast.promise(promise(), {pending: 'Loading...', success: 'Success!', error: 'Error.'});
    }
    const col = {
      field: 'show',
      headerName: 'Show',
      // valueFormatter: (value) => value,
      renderCell: params=>(params.row.Status !== 'done' ? 'waiting' : <button onClick={()=>show(params)}>Show</button>),
      width: 150,
    };

    return (
      <div>
        <button style={{marginTop: 25, marginBottom: 25}} className='btn' onClick={()=>request()}>New</button>
        <br/><br/>
        <h1>Requests</h1>
        <QueryTable 
          queryId='cut-b-cache' profile={profile} start={start} end={end}
          skip={['key', 'email', 'profile']}
          extra={[col]}
        />
        <h1>Output Table</h1>
        <QueryTable rows={table}/>
      </div>
    );
  };

  const CutBleedersApiTable = props => {
    return (
      <div>
        <br/>
        <h1>Bleeders</h1>
        {!profile || !start || !end || !dateRange.start || !dateRange.end
        || isNaN(parseInt(cutOffClicks)) || isNaN(parseFloat(cutOffACoS)) || isNaN(parseFloat(bidMultiplication)) 
        || typeof cutHighACoS !== 'boolean' || typeof useSecondDateRange !== 'boolean'
        ? <h3> Invalid Inputs </h3>
        : <div style={{maxWidth: '75vw'}}>
          <QueryTable 
            queryId='cut-bleeders-with-update' 
            showCancel
            showFetchForLater
            profile={profile} start={start} end={end}
            args={{
              'cut_off_acos':  cutHighACoS ? `${parseFloat(cutOffACoS)}` : '10000.0',
              'click_threshold': `${parseInt(cutOffClicks)}`,
              'cut': `${parseFloat(bidMultiplication)}`,
              'anotherDate': useSecondDateRange ? 'true' : 'false',
              'anotherStart': formatDate(dateRange.start),
              'anotherEnd': formatDate(dateRange.end),
            }}
          />
        </div>}
      </div>
    );
  }

  return <div>
    <h2>Cutting Bleeders</h2>
    <button style={{marginBottom: 25}} className='btn' onClick={()=>setType(type === 'file' ? 'table' : 'file')}> Type: {type === 'file' ? 'Upload' : 'Table'}</button>
    <br/>

    {type !== 'file' ? null : <TextField label='Report Name' value={reportName} onChange={e=>setReportName(e.target.value || '')} style={textFieldStyle}/>}
    <br/>
    <TextField type='number' label='Cut Off Clicks' value={cutOffClicks} onChange={e=>setCutOffClicks(e.target.value || '')} style={textFieldStyle}/>
    {/* <FormGroup>
      <FormControlLabel 
        control={<Switch value={cutHighACoS} onChange={e=>setCutHighACoS(e.target.checked)} />} 
        label="Cut High ACoS" 
      />
    </FormGroup> */}
    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
      <Switch checked={cutHighACoS} onChange={e=>setCutHighACoS(e.target.checked)} />
      <p>Cut High ACoS</p>
    </div>
    <br/>
    {cutHighACoS &&
      <TextField type='number' label='Cut Off ACoS' value={cutOffACoS} onChange={e=>setCutOffACoS(e.target.value || '')} style={textFieldStyle}/>
    }
    <br/>
    <TextField type='number' label='Bid Multiplication' value={bidMultiplication} onChange={e=>setBidMultiplication(e.target.value || '')} style={textFieldStyle}/>
    <br/>
    {type === 'file' ? null
    : <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
      <Switch checked={useSecondDateRange} onChange={e=>setUseSecondDateRange(e.target.checked)} />
      <p>Merge Second Date Range</p>
    </div>}
    <br/>
    {!useSecondDateRange || type === 'file' ? null 
    : <DateRange
      editableDateInputs={true}
      onChange={item => {
        _setDateRange([item.selection]);
        console.log('item', item);
        setDateRange({start: item.selection.startDate || null, end: item.selection.endDate || null});
      }}
      moveRangeOnFirstSelection={false}
      ranges={_dateRange}
    />}

    {type === 'file' ? <FileUploader />
     : <CutBleedersApiTable />}
  </div>
}

export default CuttingBleeders;
