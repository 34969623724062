import {useState, useEffect} from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import DataTable from "../../../Table";

const ResetButton = ({params}) => {
    const [loading, setLoading] = useState(false);

    const handleClick = async () => {
        // event.stopPropagation();
        console.log(params);
        console.log('value', params.value);
        setLoading(true);
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({job_id: params.row['Job ID']})
        }
        const response = await fetch('/reset-my-job', options);
        let data = await response.json();
        console.log('reset job data', data);
        setLoading(false);
    };

    return <button onClick={handleClick}> <Box sx={{ display: 'flex' }}> 
        <p>Reset</p> 
        {!loading ? '' : <CircularProgress size="20px"/>} 
    </Box></button>;
}

function renderResetButton(params) {
    return <ResetButton params={params}/>;
}

const col = {
    field: 'reset',
    headerName: 'Try Again',
    valueFormatter: (value) => value,
    renderCell: renderResetButton,
    width: 100,
};


const MyJobs = ({refreshPermissions}) => {
    const [loading, setLoading] = useState(false);
    const [headers, setHeaders] = useState([]);
    const [table, setTable] = useState([]);
    const [downloadTable, setDownloadTable] = useState([]);

    useEffect(()=>{
        fetchTable();
    }, [])

    const fetchTable = async () => {
        setLoading(true);
        try {
            const options = {
                method: 'POST',
                // headers: {
                //     'Content-Type': 'application/json'
                // },
                // body: JSON.stringify({})
            }
            const response = await fetch('/my-jobs', options);
            let data = await response.json();
            console.log('my jobs data', data);
            if(data.table) {
                data = data.table;
                if(data.length) {
                    setDownloadTable(data)
                    let keys = Object.keys(data[0]);
                    if (headers.length === 0 || headers.map(row=>row.field).filter(f=>!keys.includes(f)).length > 0) {
                        setHeaders(keys.map(k=>({field: k, headerName: k, width: 150})).concat(col));
                    }
                    if(!keys.includes('id')) {
                        data = data.map((row, i) => ({id: i, ...row, reset: row}))
                    }
                }
                setTable(data);
            }
        } catch (e) {
            console.log(e);
        }
        setLoading(false);
    }

    return <div>
        <h2>My Jobs</h2>
        <button className='btn' onClick={fetchTable}>Fetch</button>
        <DataTable name='My Jobs' headers={headers} table={table} loading={loading} downloadTable={downloadTable}/>
    </div>
}

export default MyJobs;





